.modalWrapper {
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Darken the background */
}

.modalBorder {
    /* Glassmorphism effect */
    backdrop-filter: blur(10px); /* Frosted glass effect */
    border: 2px solid rgba(255, 255, 255, 0.2); /* Translucent border */
    border-radius: 15px; /* Smooth edges */
    padding: 20px; /* Adds space around content */
}

.content {
    z-index: 1000;
    background-color: white;
    width: 410px;
    max-height: 100vh;
    max-width: 100vw;
    overflow: hidden;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12), 0px 24px 48px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

/* Close Button */
.modalClose {
    color: black;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
}

/* Body Content */
.lp_proactive_chat_body {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%; /* Full width to center align */
}

/* Buttons Container */
.lp_proactive_chat_buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center; /* Center buttons horizontally */
    align-items: center;
    gap: 20px; /* Gap between buttons */
    width: 100%; /* Ensures proper alignment */
}

/* Button Styles */
.chatEngagement,
.lp_chatnow_cancel_button {
    padding: 15px 30px; /* Increase padding for larger buttons */
    border-radius: 10px; /* More rounded buttons */
    min-height: 50px;
    min-width: 150px; /* Ensures buttons have a consistent size */
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px; /* Slightly larger font */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition */
}

/* Primary Button Style */
.chatEngagement {
    color: white;
    background: #0a1b46;
    border: none;
}

.chatEngagement:hover {
    background: #1b56ec; /* Darker blue on hover */
}

/* Secondary Button Style */
.lp_chatnow_cancel_button {
    color: black;
    background: white;
    border: 1px solid #ccc;
}

.lp_chatnow_cancel_button:hover {
    background: #f0f0f0; /* Light grey on hover */
}

/* Ensure shadows are consistent */
#lp-chatnow-button,
.lp_chatnow_cancel_button {
    box-shadow: none;
}
