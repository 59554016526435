// Import all mixins and variables.
@import '~@fluentui/react/dist/sass/References';


.ChevronStyle {
    margin-left: 5px;
    font-size: 11px;
}

[dir="rtl"] i.ChevronStyle {
    margin-left:0px !important;
    margin-right: 5px;
    font-size: 11px;
}

.imageContainer {
    flex: 1;
    align-self: center;
    position: relative;
    z-index: 20;
    background: linear-gradient(110deg, #0a1b46 0.48%, #3376CD 100.03%);
    background-clip: padding-box; /* Ensure a smooth gradient transition */
    border-radius: 20px;
    border: 10px solid rgba(255, 255, 255, 0);
    padding: 10px 0px;
    margin-bottom: 0px;
    display: inline-flex;
    justify-content: center; /* Center the image horizontally */
}

.image {
    max-height: 97%; /* Adjust max-height to make the image smaller */
    max-width: 97%; /* Adjust max-width to maintain aspect ratio */
    height: auto;
    width: auto;
    border-radius: 10px;
    border: 10px solid rgba(255, 255, 255, 0.20);
    transition: all 0.5s 0.5s ease;
    opacity: 1;
}
