.loader {
    z-index: 999;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffffb5;
}
