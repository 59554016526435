@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.title {
    color: #1C57EC;
    margin-bottom: 24px;
}

.titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chevron {
    display: inline-block;
    width: 8px; /* Adjust size as needed */
    height: 8px; /* Adjust size as needed */
    margin-left: 10px; /* Space between title and chevron */
    border: solid #333; /* Color of the chevron */
    border-width: 0 2px 2px 0; /* Creates the arrow shape */
    transform-origin: center; /* Set the origin for rotation */
    transition: transform 0.3s ease, border-color 0.3s ease; /* Smooth rotation and color change */
    transform: rotate(-135deg); /* This should rotate the chevron */
}

.content {
    font-size: 14px;
    line-height: 21px;
    width: 350px;
    transition: all 0.5s ease;
    position: absolute;
}

.contentHidden {
    pointer-events: none;
    transition: all 0.5s ease;
    opacity: 0;
    position: relative;
    flex: 1;
    max-height: auto;
}

.contentHiddenPrev {
    flex: 0;
    max-height: 0;
}

.contentHiddenNext {
    flex: 0;
    max-height: 0;
}

.contentClosed {
    max-height: 0;
    opacity: 0;
}

.contentOpen {
    max-height: 1000px;
    opacity: 1;
}

.taskContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: auto;
    transition: max-height 1s ease;
    margin-bottom: 20px;
}

.stepContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.stepPrev {
    pointer-events: none;
    left: -100%;
    opacity: 0;
}

.stepSelected {
    left: 0;
}

.stepNext {
    pointer-events: none;
    left: 100%;
    opacity: 0;
}

.fadeIn {
    animation-name: fadeIn;
    animation-duration: 0.0s;
    animation-iteration-count: 1;
}

.contentContainer {
    flex: 1; 
    display: flex;
    flex-direction: column;
    background-color: rgba(240, 248, 255, 0.25);
    border-radius: 0 0 8px 8px; /* Small radius for bottom corners only */
}

.wrapper {
    position: relative; /* Establish positioning context for verticalBar */
    display: flex; /* Align children horizontally */
    align-items: stretch; /* Ensure children match height */
}

.verticalBar {
    position: absolute; /* Remove from layout flow */
    top: 0; /* Align with the top of the wrapper */
    left: -20px; /* Move the bar 20px to the left of the wrapper */
    width: 6px; /* Bar width */
    height: 100%; /* Full height of the wrapper */
    background-color: #1c57ec; /* Bar color */
    border-radius: 16px; /* Optional: Rounded edges */
    flex-shrink: 0; /* Ensure it doesn't shrink */
    z-index: 1; /* Keep the bar above other elements if needed */
}