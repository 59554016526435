// Import all mixins and variables.
@import '~@fluentui/react/dist/sass/References';

$standard-spacing: 20px;

.tourFullScreen {
  background-color: black;

  .tourContainer {
    height: 100vh;
    display: flex;
    padding: 0;
    margin-top: -36px;

    .header {
      display: none;
      flex: 0 1 auto;
      margin-top: 15px;
      margin-bottom: -20px;

      .demoModeToggle {
        label {
          color: $ms-color-white;
        }
      }
    }

    .body {
      flex: 0 1 100%;
      margin: auto 0;
      overflow: hidden;

      .rightPane {
        flex: 0 1 100%;
        margin: auto 0;

      }

      .fullScreenImageWrapper {
        margin: auto 0;
      }

    }

  }
}

.tourContainer {
  @include ms-fontWeight-regular;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0 auto;
  // TODO: Do we need a different width setting?
  min-width: 1200px;
}

.body {
  display: flex;
  flex-direction: row;
  margin: 0px $standard-spacing * 2;
  align-items: flex-start;
}

