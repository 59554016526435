.buttonGroupStyle {
	margin-left:2%;
	position:absolute;
	margin-bottom: 20px;
}

.backButtonIconStyle {
	color:black;
	padding-right:15px;
}

.shareButtonStyle {
  color: var(--ms-dynamics-white);
  border-radius: var(--ms-dynamics-border-radius);
  background: var(--ms-dynamics-navy);
  width: 9.375rem;
  height: 3rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.shareButtonStyle:hover {
	background-color: #1b56ec;
	color: white;
}

.shareButtonIconStyle {
	padding-left: 0.25rem;
}

.collectionButtonStyle {
	padding-left:15px;
	padding-right:15px;
	color:black;
}
