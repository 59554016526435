.modalWrapper {
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
}

.content {
  z-index: 1000;
  background-color: white;
  width: 610px;
  padding: 28px 35px;
  max-height: 100vh;
  max-width: 100vw;
  overflow: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12), 0px 24px 48px rgba(0, 0, 0, 0.2);
}

.close {
  position: absolute;
  right: 5px;
  top: 5px;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  margin-left: 30px;
  padding: 0;
  cursor: pointer;
}

.link i {
  margin-left: 4px;
}

[dir="rtl"] a.link {
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    margin-left: 0px !important;
    margin-right: 30px;
    padding: 0;
    cursor: pointer;
}

.linkRow {
  margin-top: 24px;
  pointer-events: all;
  display: flex;
  align-items: center;
}
