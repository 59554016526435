.endDemoCtaContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 79px;
    height: auto;
    flex-wrap: wrap;
}

.endDemoCta {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 88px;
    margin-bottom: 0px;
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
    color: black;
    width: 410px;
}

.icon {
    margin-bottom: 16px;
    height: 48px;
}

.link {
    text-decoration: none;
    font-weight: bold;
    padding: 0;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
}
