$theme-color: rgb(0, 120, 212);
$beak-size: 10px;
$beak-offset: 20px;
$animation-duration: 1s;

// Combine animations into one keyframe
@keyframes pulse {
  0% {
    transform: scale(1.5);
    opacity: 0.5;
  }

  100% {
    transform: scale(2.3);
    opacity: 0;
  }
}

.stepIndicatorContainer {
  width: 100%;
  height: 100%;
  pointer-events: none;
  top: 0;
  left: 0;
  position: absolute;
}

.stepIndication {
  pointer-events: all;
  display: block;
  position: relative;
  width: 35px;
  height: 35px;
  transform: translate(-17.5px, -17.5px);
  background-color: $theme-color;
  border-radius: 50%;
  cursor: pointer;

  &:before, &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    transform-origin: center;
    box-sizing: border-box;
    animation-duration: $animation-duration;
    animation-iteration-count: infinite;
  }

  &:before {
    border: 15px solid $theme-color;
    animation-name: pulse;
  }

  &:after {
    border: 10px solid $theme-color;
    animation-name: pulse;
  }
}

.beak {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

// Beak mixin to handle all directions
@mixin beak($direction, $color) {
  @if $direction == up {
    border-left: $beak-size solid transparent;
    border-right: $beak-size solid transparent;
    border-bottom: $beak-size solid $color;
    top: -$beak-offset;
    left: 50%;
    transform: translateX(-50%);
  }
  @else if $direction == down {
    border-left: $beak-size solid transparent;
    border-right: $beak-size solid transparent;
    border-top: $beak-size solid $color;
    bottom: -$beak-offset;
    left: 50%;
    transform: translateX(-50%);
  }
  @else if $direction == left {
    border-top: $beak-size solid transparent;
    border-bottom: $beak-size solid transparent;
    border-right: $beak-size solid $color;
    top: 50%;
    right: -$beak-offset;
    transform: translateY(-50%);
  }
  @else if $direction == right {
    border-top: $beak-size solid transparent;
    border-bottom: $beak-size solid transparent;
    border-left: $beak-size solid $color;
    top: 50%;
    left: -$beak-offset;
    transform: translateY(-50%);
  }
}

.beakTop {
  @include beak(up, $theme-color);
}

.beakBottom {
  @include beak(down, $theme-color);
}

.beakLeft {
  @include beak(left, $theme-color);
}

.beakRight {
  @include beak(right, $theme-color);
}

.next {
  position: absolute;
  cursor: pointer;
  right: 5px;
  bottom: 7px;
}
