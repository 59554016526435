body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI','Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    margin: 0;
}

div:lang(zh-CN) > *, header:lang(zh-CN) > * {
    font-family: "Microsoft YaHei", "Microsoft YaHei UI",-apple-system, BlinkMacSystemFont, 'Segoe UI','Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

div:lang(zh-TW) > *, header:lang(zh-TW) > * {
    font-family: "Microsoft JhengHei", "Microsoft JhengHei UI",-apple-system, BlinkMacSystemFont, 'Segoe UI','Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

div:lang(ko-KR) > *, header:lang(ko-KR) > * {
    font-family: "Malgun Gothic",-apple-system, BlinkMacSystemFont, 'Segoe UI','Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

div:lang(ja-JP) > *, header:lang(ja-JP) > * {
    font-family: "Meiryo", "Yu Gothic", "Yu Gothic UI",-apple-system, BlinkMacSystemFont, 'Segoe UI','Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ms-Overlay {
    position: static;
    z-index: 100;
}

h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 40px;
}

h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
}

p {
    margin-block-start: 0 !important;
    font-style: normal;
    font-weight: normal;
}

.ms-Button-label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.08px;
}

.ms-Dropdown-title,
.ms-Dropdown,
.ms-Dropdown:hover,
.ms-Dropdown:active {
    outline: #75757a !important;
    border-color: #75757a !important;
}

    .ms-Dropdown:focus {
        outline: 1px solid lightgrey !important;
    }

.ms-Dropdown-title,
.ms-Dropdown-caretDownWrapper,
.ms-Dropdown-optionText {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.ms-Dropdown-item {
    min-height: 25px !important;
    padding: 0 10px !important;
}

.modal-title {
    margin: 0;
    margin-bottom: 24px;
    line-height: 30px;
}

.ms-Dropdown-callout .ms-Callout-main {
    overflow: hidden;
}

.video-container iframe {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.footer-top {
    margin-top: 60px;
}
