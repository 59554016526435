.loader {
    z-Index: 999;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    align-Items: center;
    justify-Content: center;
    background: #ffffffb5;
}


