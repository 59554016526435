  .landingPageContentContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      transition: all 0.5s linear;
      width: 400px;
      opacity: 1;
  }

  .stepsContentContainer {
      transition: all 0.5s linear;
      width: 400px;
      display: flex;
      flex-direction: column; /* Stack children vertically */
      margin-bottom: 40px; /* Ensure margin is being applied */
  }

  .stepsContentHidden {
      flex: 0;
      width: 0;
      opacity: 0;
      overflow: hidden;
  }

  .stepsContent {
      position: relative;
      color: #161616;
      width: 350px;
      transition: all 0.5s 0.5s ease;
      margin: 0px; /* Ensure no margin interferes with the divider */
      padding: 0; /* Prevent padding conflicts */
  }

  .sectionTitle {
      text-decoration: none;
      color: #525252;
      transition: all 0.5s 0.5s ease;
  }

  .divider {
    height: 1px; /* Fixed thickness */
    background-color: #ddd; /* Neutral color */
    padding: 0; /* Ensure no padding affects the height */
    width: 100%; /* Ensure full width of the parent container */
    box-sizing: border-box; /* Prevent padding or border from altering dimensions */
}

.stepTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer; /* Indicate clickable area */
    transition: background-color 0.3s ease; /* Smooth hover effect */
}

.stepTitleContainer:hover {
    background-color: #f5f5f5; /* Light gray background on hover */
}

.stepTitleContainer:hover .title {
    color: #0047ab; /* Darker blue on hover */
}

.stepTitleContainer:hover .chevron {
    border-color: #0047ab; /* Change chevron color on hover */
}

.title {
    margin: 0;
    font-size: 16px;
    color: #1C57EC;
    transition: color 0.3s ease; /* Smooth color transition */
}

.chevron {
    display: inline-block;
    width: 8px; /* Adjust size as needed */
    height: 8px; /* Adjust size as needed */
    margin-left: 10px; /* Space between title and chevron */
    border: solid #333; /* Color of the chevron */
    border-width: 0 2px 2px 0; /* Creates the arrow shape */
    transform-origin: center; /* Set the origin for rotation */
    transition: transform 0.3s ease, border-color 0.3s ease; /* Smooth rotation and color change */
    transform: rotate(45deg); /* This should rotate the chevron */
}



