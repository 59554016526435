.anotherDemoContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: white;
    background-color: #0A1B46;
}

.title {
    margin-top: 0;
    margin-bottom: 30px;
}

.label {
    margin-bottom: 8px;
}

.button {
    background-color: #FFFFFF;
    border: 1px solid #000000;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.18), 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    margin-top: 48px;
    margin-bottom: 93px;
}

.endButton {
    padding: 25px 20px;
    margin: 20px;
    line-height: 100%;
    font-weight: 600;
    font-size: 16px;
    border-radius: 10px;
    background-color: #1b57ec;
    color: white;
    border: none;
  }


