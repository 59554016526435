.imageFrame {
  background: linear-gradient(110deg, #0a1b46 0.48%, #3376CD 100.03%);
  background-clip: padding-box; /* Ensure a smooth gradient transition */
  border-radius: 20px;
  border: 10px solid rgba(255, 255, 255, 0);
  padding: 10px;
  display: inline-block;
}

.imageContainer {
  flex: 1;
  align-self: center;
  position: relative;
  max-height: calc(100vh - (160px));
  max-width: calc((100vh - (160px))*1.6);
  min-height: 500px;
  min-width: calc((500px)*1.6);
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video {
  height: 100vh;
  max-height: calc(100vh - (160px));
  filter: drop-shadow(16px 26px 20px darkGrey);
}

.image {
  border-radius: 5px;
  border: 10px solid rgba(255, 255, 255, 0.20);
  transition: all 0.5s 0.5s ease;
  opacity: 1;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  width: auto;
  height: auto;
}

.imageContainer.vertical .image {
  max-height: calc(100vh - 160px);
  width: auto;
  padding: 10px 80px;
}

/* Conditional styling based on aspect ratio */
.imageContainer.horizontal .image {
  padding: 10px;
  max-height: 75vh;
}

.placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: linear-gradient(110deg, #0a1b46 0.48%, #3376CD 100.03%);
}

.imageRight {
  transition: none;
}

.landingPageContentHidden {
  flex: 0;
  width: 0;
  opacity: 0;
}

.stepsContentContainer {
  transition: all 0.5s linear;
  width: 400px;
}

.stepsContentHidden {
  flex: 0;
  width: 0;
  opacity: 0;
  overflow: hidden;
}

.stepsContent {
  position: relative;
  color: #161616;
  width: 350px;
}

.sectionTitle {
  text-decoration: none;
  color: #525252;
}
