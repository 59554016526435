// Import all mixins and variables.
@import '~@fluentui/react/dist/sass/References';
@import 'dynamics.css';

$standard-spacing: 20px;


.tourUIHidden {
  .buttons {
    opacity: 0.001;
    transition: opacity 0.2s
  }
}

.tourFullScreen {
  background-color: black;

  .header {
    flex: 0 1 auto;
    margin-top: 0px;
    margin-bottom: -20px;
    padding: 0px 0px;
  }
}

.landing {
  margin: $standard-spacing $standard-spacing* 2 !important;
}

.header {
  display: flex;
  flex: 0 1 100%;
  margin: 0px $standard-spacing * 2;
  padding: $standard-spacing * 2 0px;
  justify-content: space-between;

  .titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 50px;
    @include ms-font-xl;
    @include ms-fontWeight-semibold;
    color: #737373;

    .logoIcon {
      height: 30px;
      margin-right: $standard-spacing;
    }

    .title {
      //Title pipe
      border-left: 2px solid #000;
      padding-left: 14px;
    }
  }

  .buttons {
    justify-content: flex-end;
    display: flex;

    .demoModeToggle {
      margin-left: $standard-spacing;
      margin-top: 8px;

      label {
        color: $ms-color-white;
      }
    }

    .displayModeButton {
      margin-left: $standard-spacing;
    }
  }
}

//ShortForm Classes

.headerShort {
  color: #0a1b46;
  height: 105px;
  min-width: 1255px;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.headerShortDark {
  background-color: #0a1b46;
}


.titleLight {
    border-left: 1px solid rgb(82, 82, 82);
    color: rgb(82, 82, 82);
}

[dir="rtl"] h2.titleLight {
    border-right: 1px solid rgb(82, 82, 82);
    border-left: none!important;
    padding-right: 15px!important;
    color: rgb(82, 82, 82);
}

.titleDark {
  border-left: 1px solid white;
  color: white;
}

[dir="rtl"] h2.titleDark {
    border-right: 1px solid white;
    border-left: none !important;
    padding-right: 15px !important;
    color: white;
}

.demoTitle {
  font-size: 22px;
  font-weight: 600;
  margin-left: 15px;
  margin-top: 34px;
  padding-left: 15px;
  line-height: 34px;
  height: 34px;
}

[dir="rtl"] h2.demoTitle {
    font-size: 22px;
    font-weight: 600;
    margin-right: 15px;
    margin-top: 34px;
    padding-right: 15px;
    line-height: 34px;
    height: 34px;
}

.logoContainer {
  display: flex;
}

.image {
  height: 24px;
}

.imageContainer {
  margin-top: 41px;
  margin-left: 36px;
}

[dir="rtl"] a.imageContainer {
    margin-top: 41px;
    margin-left: 0px!important;
    margin-right:36px;
}

.shortTitle {
  font-size: 24px;
  align-self: center;
  border-left: 1px solid #ffffff;
  padding: 0 35px;
  color: #ffffff;
}

.buttonContainer {
  margin-top: 33px;
  margin-right: 36px;
}

[dir="rtl"] div.buttonContainer {
    margin-top: 33px;
    margin-right:0px !important;
    margin-left: 36px;
}

.buttonLight {
    border: none;
    color: white;
    background-color: #0a1b46 !important;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

.buttonLight:hover {
  background-color: #1b57ec !important;
  text-decoration: none;
  color: white;
}

.buttonLight:focus {
  background-color: rgb(243, 242, 241) !important;
  color: rgb(32, 31, 30);
}

.buttonLight:active {
  background-color: #1b57ec !important;
  color: white;
}

.buttonDark {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
  background-color: #1b57ec;
  text-decoration: none;
  border: none;
  color: white;
}

.buttonDark:hover {
  background-color: #ffffff !important;
  color: #0a1b46;
  text-decoration: none;
}

.buttonDark:active:hover {
  background-color: #ffffff !important;
  color: #0a1b46;
  text-decoration: none;
}

.buttonDark:focus {
  background-color: #ffffff !important;
  color: #1b57ec;
}

.buttonDark:active {
  background-color: #ffffff !important;
  color: #1b57ec;
}

.button {
  padding: 15px 20px;
  line-height: 100%;
  font-weight: 600;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  background-color: #1b57ec;
  color: white;
  text-decoration: none;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}