.endDemoStoryContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 80px 84px;
    align-items: stretch;
    border-radius: 20px;
    background-image: linear-gradient(110deg,#FF9449 0.48%,#FEF100 100.03%);
    margin: 40px;
}

.endDemoStoryTile {
    flex: 1;
    align-self: center;
    width: 1280px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    background-color: #ffffff;
    border-radius: 5px;
    overflow: hidden;
    color: #0a1b46;
    height: 430px;
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.5);
}

.image {
    height: 430px;
    flex: 1;
}

.text {
    flex: 1;
    width: 420px;
    margin-left: 94px;
    padding-right: 34px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.heading {
    font-size: 24px;
    line-height: 29px;
    margin-top: 0;
}

.button {
    padding: 25px 20px;
    margin-top: 24px;
    background-color: #1b56ec;
    color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: none;
    text-decoration: none;
}

.button:hover{
    background-color: white;
    color: #0a1b46;
    border: 1px solid #1b56ec;
}
