// Import all mixins and variables.
@import '~@fluentui/react/dist/sass/References';

$standard-spacing: 20px;

.leftPane {
    flex: 0 1;
    padding-right: $standard-spacing * 2;

    .breadcrumbs {
        margin-bottom: $standard-spacing;

        button {
            @include ms-fontSize-s;
        }
    }

    .stepTitle {
        @include ms-fontWeight-semibold;
        @include ms-fontColor-blue;
        padding-top: 1.2em;
    }

    .stepDetails {
        max-height: calc(100vh - 455px);
        min-height: 50px;
        overflow-y: auto;
        margin-top: -10px;
        margin-bottom: 2em;
    }

    .stepList {
        list-style-type: disc;
        margin-left: -10px;
        padding-right: 5px;
        margin-bottom: 2em;
    }

    .stepInstructions {
        padding-top: .5em;
        @include ms-fontWeight-semibold;
    }

    .prevButton {
        margin-right: $standard-spacing;
    }

    .prevButtonWrapper {
        flex-wrap: nowrap;
    }

    .nextButtonWrapper {
        flex-wrap: nowrap;
    }
}
