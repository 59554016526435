// Import all mixins and variables.
@import '~@fluentui/react/dist/sass/References';

$standard-spacing: 20px;



//Center image vertically
.fullScreenImageWrapper {
  margin: auto 0;
}

.rightPane {
    flex: 1 1 0%;
    justify-content: center;
    display: flex;

    .clickableAnchor {
        cursor: pointer;
    }

    .hiddenAnchor {
        cursor: default;
    }

    .fullScreenImageWrapper {
        width: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        background-color: $ms-color-gray90;
    }

    .stepImageWrapper {
        box-shadow: $ms-depth-shadow-8;
        position: relative;
        display: flex;

        .stepImage {
            display: block;
        }

        .stepImage.standardScreenImage {
            max-height: calc(100vh - 120px);
            max-width: calc((100vh - 120px) * 1.6);
            min-height: 500px;
            min-width: 800px;
        }

        .stepImage.fullScreenImage {
            max-height: calc(100vh - 60px);
            min-height: 500px;
            min-width: 800px;
        }

        .buttonContainer {
            position: absolute;
            width: 0px;
            height: 0px;
        }
    }
}
