$theme-color: rgb(0, 120, 212);
$beak-size: 10px;
$beak-offset: 20px;
$animation-duration: 1s;

// Combine animations into one keyframe
@keyframes pulse {
    0% {
      transform: scale(1.5);
      opacity: 0.5;
    }
  
    100% {
      transform: scale(2.3);
      opacity: 0;
    }
  }

  .blinkingDot {
    pointer-events: all;
    display: block;
    position: relative;
    width: 35px;
    height: 35px;
    transform: translate(-10px, -0px);
    background-color: $theme-color;
    border-radius: 50%;
    cursor: pointer;
  
    &:before, &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 50%;
      transform-origin: center;
      box-sizing: border-box;
      animation-duration: $animation-duration;
      animation-iteration-count: infinite;
    }
  
    &:before {
      border: 15px solid $theme-color;
      animation-name: pulse;
    }
  
    &:after {
      border: 10px solid $theme-color;
      animation-name: pulse;
    }
  }

.close {
    position: absolute; 
    right: 0;
    top: 0;
    transition: all 0.5s 0.5s ease;
}

.linkRow {
    transition: all 0.5s 0.5s ease;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: -10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.pagination {
    transition: all 0.5s 0.5s ease;
    position: relative;
    top: -4px;
}


.hideLink {
    visibility: hidden;
}


.link {
    cursor: pointer; 
    text-decoration: none;
    transition: all 0.5s 0.5s ease;
}

.link:focus {
    outline: 1px solid rgb(0, 103, 184);
}

.paragraph {
    font-size: 16px;
    line-height: 24px;
    transition: all 0.5s 0.5s ease;
}

.empty {
    width: 18px;
    height: 18px;
    transition: all 0.5s 0.5s ease;
}

/* Common styles for both "Next" and "Previous" buttons */
.nextButton,
.prevButton {
  width: 40px; /* Adjust the width as needed */
  height: 40px; /* Adjust the height as needed */
  border: 2px solid #0a1b46;
  border-radius: 50%; /* Make it a circle */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px; /* Adjust the font size as needed */
  color: #0a1b46;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.nextButton:hover,
.prevButton:hover {
  background-color: #1b57ec; /* Background color on hover */
  color: #ffffff; /* Text color on hover */
  border: 2px solid #1b57ec;
  cursor: pointer;
}

.nextButton {
    margin-top: -1px;
    margin-right: -2px; /* Add margin if needed to separate from the "Previous" button */
  }
  
  /* Styles for the "Previous" button */
  .prevButton {
    margin-left: -2px; /* Add margin if needed to separate from the "Next" button */
  }