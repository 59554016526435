.section {
    border-left: 2px solid;
    border-color: #1C57EC;
    padding-left: 20px;
    margin: 40px 0;
}

.sectionTitle {
    color: #1C57EC;
    font-weight: 700;
    text-decoration: none;
}

.notFocused {
    opacity: 0.5;
}

.focused {
    opacity: 1.0;
}
