@keyframes fadeAnim {
    0% {
        opacity: 0,
    }

    100% {
        opacity: 1,
    }
}


.page {
    background-color: #FFFFFF;
}

.pageContent {
    display: flex;
    flex-direction: row;
    min-width: 1200px;
    width: 100%;
    overflow: visible;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 20px 40px; /* Add vertical padding */
}

.fadeIn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 0 25px; /* Balanced horizontal padding */
    animation-name: fadeAnim;
    animation-duration: 0.5s; /* Make fade-in effect visible */
    animation-iteration-count: 1;
}

.content {
    background-color: #FFFFFF;
}
