.contactWidget {
    display: inline-block;
    position: fixed;
    top: calc(50% - 100px);
    z-index: 1000;
    background: #0a1b46;
    color: white;
    box-shadow: 0 3.2px 7.2px 0 rgba(0,0,0,.132), 0 0.6px 1.8px 0 rgba(0,0,0,.108);
    right: 0;
    border-radius: 4px 4px 0px 4px;
}

.contactWidgetList {
    list-style: none;
    padding: 10px;
    margin: 0;
}

.contactWidgetChatItem {
    padding: 0;
    margin-bottom: 0;
    height: 52px;
    flex-direction: row;
    color: white;
}

.contactWidgetChatItem:hover{
    background-color: #1b56ec;
}

.contactWidgetListItem {
    padding: 0;
    margin-bottom: 0;
    display: flex;
    height: 52px;
    flex-direction: row;
    border-bottom: none;
}

.contactWidgetListItem:hover{
    background-color: #1b56ec;
}

.contactWidgetLastListItem {
    padding: 0;
    margin-bottom: 0;
    display: flex;
    height: 52px;
    flex-direction: row;
}

.contactWidgetLastListItem:hover{
    background-color: #1b56ec;
}

.contactWidgetIcon {
    background: #0a1b46;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactWidgetIcon:hover{
    background-color: #1b56ec;
}

.contactWidgetLastIcon {
    background: #0a1b46;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactWidgetLastIcon:hover{
    background-color: #1b56ec;
}

.contactWidgetImg {
    width: 48px;
    height: 48px;
    padding: 6px;
}

.contactWidgetText {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 12px;
}

.contactWidgetExpandText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 12px;
}

.contactWidgetLink {
    display: inline-block;
    position: relative;
    padding: 2px 0;
    background-color: transparent;
    color: white;
    font-size: 16px;
    font-weight: semi-bold;
    text-decoration: none;
}

.contactWidgetMiniText {
    font-size: 11px;
    color: white;
}

.contactWidgetButton {
    position: absolute;
    background-color: transparent;
    color: #000;
    height: 100%;
    width: 50px;
    z-index: 1;
    cursor: pointer;
    border: none;
    border-radius: 0;
    color: white;
}

.contactWidgetButtonExpand {
    left: 2px;
    right: auto;
    position: absolute;
    background-color: transparent;
    color: #000;
    height: 100%;
    width: 50px;
    z-index: 1;
    cursor: pointer;
    border: none;
    border-radius: 0;
}

.contactWidgetClose {
    position: absolute;
    top: 100%;
    background-color: #007869;
    color: #FFF;
    font-size: 11px;
    padding: 5px 10px;
    display: none;
    border: 1px solid #007869;
    border-radius: 0;
    right: 0;
}

.contactWidgetExpandClose {
    position: absolute;
    top: 100%;
    background-color: white;
    color: #0a1b46;
    font-size: 14px;
    font-weight: semi-bold;
    padding: 5px 10px;
    display: block;
    border-width: 0 2.5px 2.5px 2.5px;
    border-style: solid;
    border-color: transparent #0a1b46 #0a1b46 #0a1b46;
    border-radius: 0px 0px 4px 4px;
    right: 0;
}

// Live Person Styles
.lp_iframe_chatwindow {
    z-index: 1000;
    position: fixed;
    bottom: 0;
    right: 0;
    height: 0;
    display:none;
    color: white;
}


