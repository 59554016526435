$standard-spacing: 20px;

.page {
    background-color: #FFFFFF;
}

.pageContent {
    display: flex;
    flex-direction: row;
    min-width: 1200px;
    width: 100%;
    overflow: visible;
    justify-content: space-between;
    align-items: stretch;
    box-sizing: border-box;
    padding: 0 40px;
}

.landingPageContentContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    transition: all 0.5s linear;
    width: 400px;
    opacity: 1;
    margin: 3em;
}

.landingPageContent {
    position: relative;
    top: -20%;
    color: #161616;
    width: 400px;
}

.heading {
    margin-bottom: 30px;
}

.description {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 26px;
    display: block;
    overflow-wrap: break-word;
    max-width: 100%;
}

.button {
  color: var(--ms-dynamics-white);
  background: var(--ms-dynamics-navy);
  box-shadow:0px 0px 1px rgba(0, 0, 0, 0.18), 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: var(--ms-dynamics-border-radius);
  margin-top: 30px;
}

.imageContainer {
    flex: 1;
    align-self: center;
    position: relative;
    z-index: 20;
    background: linear-gradient(110deg, #0a1b46 0.48%, #3376CD 100.03%);
    background-clip: padding-box;
    border-radius: 20px;
    border: 10px solid rgba(255, 255, 255, 0);
    padding: 10px 0px;
    margin-bottom: 0px;
    display: inline-flex;
    justify-content: center; // Center the image horizontally
  
    &.vertical .image {
      max-height: 78vh; // Limit height for vertical images
      max-width: 50%; // Optionally restrict width to maintain aspect ratio
    }
  
    &.horizontal .image {
      max-width: 95%; // Allow more width for horizontal images
      max-height: 78vh;
    }
  }
  
  .image {
    max-height: 97%; // General max height
    max-width: 97%; // General max width
    height: auto;
    width: auto;
    border-radius: 10px;
    border: 10px solid rgba(255, 255, 255, 0.20);
    transition: all 0.5s ease;
    opacity: 1;
  }