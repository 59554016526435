/* Variables */
:root {
  /* Primary Palette */
  --ms-dynamics-navy: #0a1b45;
  --ms-dynamics-blue: #0078d4;
  --ms-dynamics-light-tan: #eae4dc;
  --ms-dynamics-extra-dark-gray: #2f2f2f;
  --ms-dynamics-medium-gray: #a4a4a4;
  --ms-dynamics-extra-light-gray: #f2f2f2;
  --ms-dynamics-white: white;

  /* Accent Colors */
  --ms-dynamics-orange: #d83b01;
  --ms-dynamics-light-orange: #ff9349;
  --ms-dynamics-yellow: #ffb900;
  --ms-dynamics-light-yellow: #fef000;
  --ms-dynamics-ocean: #1392b4;
  --ms-dynamics-light-blue: #50e6ff;
  --ms-dynamics-violet: #770180;
  --ms-dynamics-light-purple: #d59dff;

  /* Footer Colors */
  --footer-background: var(--ms-dynamics-navy);
  --footer-text-color: var(--ms-dynamics-white);

  /* Other */
  --ms-dynamics-border-radius: 0.625rem;
}
