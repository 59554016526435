// Import all mixins and variables.
@import '~@fluentui/react/dist/sass/References';

.stepTitle {
    @include ms-fontWeight-semibold;
    @include ms-fontColor-blue;
}

.scrollableDetails {
    max-height: 100px;
    overflow-y: auto;
}

.beakAbsolute {
    position: absolute;
}

.bubbleStepList {
    list-style-type: disc;
    margin-left: -5px;
}

.stepInstructions {
    @include ms-fontWeight-semibold;
}

.coachmarkDot {
    pointer-events: all;
    display: block;
    position: relative;
    width: 30px;
    height: 30px;
    background-color: rgb(0, 120, 212);
    border-radius: 100%;
    cursor: pointer;
}

@keyframes beforePulse {

    0% {
        transform: scale(1.5);
        opacity: .5;
    }

    20% {
        transform: scale(1.8);
        opacity: 0;
    }

    20.1% {
        transform: scale(1);
        opacity: .5;
    }

    100% {
        transform: scale(1);
        opacity: .5;
    }
}

@keyframes afterPulse {

    0% {
        transform: scale(2);
        opacity: .22;
    }

    20% {
        transform: scale(2.3);
        opacity: 0;
    }

    20.1% {
        transform: scale(1);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: .22;
    }
}

.coachmarkPulsingDot {
    pointer-events: all;
    display: block;
    position: relative;
    width: 30px;
    height: 30px;
    background-color: rgb(0, 120, 212);
    border-radius: 100%;
    cursor: pointer;

    &:before {
        border: 15px solid rgb(0, 120, 212);
        content: "";
        width: 100%;
        display: block;
        position: absolute;
        height: auto;
        border-radius: 100%;
        transform-origin: center center;
        box-sizing: border-box;
        animation-name: beforePulse;
        animation-duration: 5s, 5s;
        animation-iteration-count: infinite;
    }

    &:after {
        border: 15px solid rgb(0, 120, 212);
        content: "";
        width: 100%;
        display: block;
        position: absolute;
        height: auto;
        border-radius: 100%;
        transform-origin: center center;
        box-sizing: border-box;
        animation-name: afterPulse;
        animation-duration: 5s;
        animation-iteration-count: infinite;
    }
}

.nextButtonPadding {
    margin-top: 27px; //Moves down so don't touch button.
    margin-left: 40px; //Matches Beak left value.
}

.prevButtonPadding {
    margin-top: 27px; //Moves down so don't touch button.
    margin-left: 150px; //Matches Beak left value.
}

.coachmarkDiv {
    transform: translate(-20px, -20px); //Translate Coachmark component onto markerButton center.
}

.bubbleCalloutTop {
    margin-top: 15px;
    margin-bottom: -20px;
    margin-left: -5px;
    margin-right: 5px;
}

.bubbleCalloutBottom {
    margin-top: 10px;
    margin-bottom: 25px;
    margin-left: -5px;
    margin-right: 5px;
}

.bubbleCalloutLeft {
    margin-top: -5px;
    margin-bottom: 5px;
    margin-left: 25px;
    margin-right: -15px;
}

.bubbleCalloutRight {
    margin-top: -5px;
    margin-bottom: 5px;
    margin-left: -25px;
    margin-right: 25px;
}

